.tag-input-container .form-control {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem;
    min-height: 3rem;
  }
  
  .tag-input-container .form-control input {
    border: none;
    outline: none;
    flex-grow: 1;
    min-width: 100px;
  }

  .swiper {
    overflow: hidden; /* Ensure no overflow */
  }
  
  .swiper-slide {
    width: 100%; /* Ensure slides take full width */
    height: auto; /* Allow height to adjust based on content */
  }
  
  